<template>
  <v-container>
    <flight-details />
  </v-container>
</template>

<script>
import FlightDetails from '@/components/Flight/FlightDetails.vue'
export default {
  name: 'Flight',
  components: {
    FlightDetails
  }
}
</script>

<style></style>
