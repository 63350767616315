<template>
  <v-container>
    <v-card>
      <v-card-title>Identification</v-card-title>
      <v-card-text v-if="flight && flight.state > 0">
        <v-chip
          class="ma-2"
          :color="getStateColor(flight.state)"
          text-color="white"
        >
          {{ getState(flight.state) }}
        </v-chip>
        Plan state: {{ flight && flight.lastEvent }}
      </v-card-text>
      <v-card-text>
        Origin:
        <router-link
          v-if="flight && flight.origin"
          :to="{ name: 'Station', params: { icaoCode: flight.origin }}"
        >{{ flight.origin }}</router-link>
        /
        Destination:
        <router-link
          v-if="flight && flight.destination"
          :to="{ name: 'Station', params: { icaoCode: flight.destination }}"
        >{{ flight.destination }}</router-link>
        //
        Registration:
        <router-link
          v-if="flight && flight.registration"
          :to="{ name: 'AircraftDetails', params: { aircraftId: flight.registration }}"
        >{{ flight.registration }}</router-link>
        /
        Callsign:
        <router-link
          v-if="flight && flight.callsign"
          :to="{ name: 'AircraftDetails', params: { aircraftId: flight.callsign }}"
        >{{ flight.callsign }}</router-link>
      </v-card-text>
      <v-card-text>{{ flight && flight.filedRoute }}</v-card-text>

    </v-card>
    <v-card>
      <v-card-title>Times</v-card-title>
      <times-grid :flight="flight" />
    </v-card>
    <v-card>
      <div class="sides">
        <div class="side">
          <gmap-map
            v-if="positions.length > 0"
            :center="center"
            :zoom="7"
            ref="gmap"
            style="width: 100%; height: 100%"
          >
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            />
            <gmap-marker
              v-for="(m, i) in markers"
              :key="i"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :label="m.label"
              @click="toggleInfoWindow(m, i)"
            />
            <gmap-polyline
              :path="polyLine"
              stroke-weight="1"
            />
          </gmap-map>
          <div v-else>No positions</div>
        </div>
      </div>
    </v-card>
    <v-card>
      <GChart
        :settings="{ packages: ['corechart']}"
        :data="chartData"
        type="LineChart"
        :options="chartOptions"
        @ready="onChartReady"
      />
    </v-card>
    <v-card>
      <v-expansion-panels multiple>
        <v-expansion-panel @change="getRaw();">
          <v-expansion-panel-header>Raw flight</v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre style="font-size: 10px;height: 700px; width: 100%;overflow: scroll !important;">{{rawData}}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel @change="getRaw();">
          <v-expansion-panel-header>Raw plan</v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre style="font-size: 10px;height: 700px; width: 100%;overflow: scroll !important;">{{rawPlan}}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { GChart } from 'vue-google-charts'
import TimesGrid from './TimesGrid.vue'
import { gmapApi } from 'gmap-vue'
import { FlightClient, IConfig, FlightState } from '@/api/flightbooking'

export default {
  components: {
    TimesGrid,
    GChart,
  },
  data: () => ({
    chartData: null,
    chartOptions: {
      hAxis: {
        format: 'dd/MM/yyyy HH:mm z',
      },
      legend: 'none',
      tooltip: { isHtml: true },
      title: 'Altitude (FL)',
    },
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    headers: [
      {
        text: 'EOBT',
        value: 'eobt',
        groupable: false,
      },
      { text: 'Origin', value: 'origin' },
      { text: 'Destination', value: 'destination' },
    ],
    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35,
      },
    },
    positions: [],
  }),
  computed: {
    ...mapState('flights', ['flight', 'rawData', 'rawPlan']),
    markers() {
      const pos = this.positions

      return pos.map((d, i) => ({
        position: {
          lat: d.latitude,
          lng: d.longitude,
        },
        level: d.level,
        timeOver: new Date(d.timeOver).toNiceUtc(),
        levelUnit: 'F',
        label: '' + (i + 1),
      }))
    },

    polyLine() {
      const pos = this.positions

      return pos.map((d) => ({
        lat: d.latitude,
        lng: d.longitude,
      }))
    },
    center() {
      const lats = this.positions.map((d) => parseFloat(d.latitude))
      const lngs = this.positions.map((d) => parseFloat(d.longitude))

      const maxLat = Math.max(...lats)
      const minLat = Math.min(...lats)
      const midLat = (maxLat + minLat) / 2

      const maxLng = Math.max(...lngs)
      const minLng = Math.min(...lngs)
      const midLng = (maxLng + minLng) / 2

      return { lat: midLat, lng: midLng }
    },
    google: gmapApi,
  },
  created() {
    this.$store.dispatch('flights/getFlight', this.$route.params.flightId)
  },
  mounted() {
    const client = new FlightClient(new IConfig())
    client.getFlightPositions(this.$route.params.flightId).then((pos) => {
      this.positions = pos.sort((a, b) => {
        return a.timeOver.getTime() - b.timeOver.getTime()
      })
    })
  },
  methods: {
    getState: function (i) {
      return FlightState[i]
    },
    getStateColor: function (i) {
      if (i == FlightState.Cancelled) return 'red'
      if (i == FlightState.Terminated) return 'blue'
      if (i == FlightState.AtcActivated || i == FlightState.TactActivated)
        return 'green'
      if (i == FlightState.Filed) return 'yellow'
    },
    getRaw: function () {
      if (!this.rawData || !this.rawFlight) {
        this.$store.dispatch('flights/getRaw', this.$route.params.flightId)
      }
    },
    generateBounds(fromWhere) {
      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds()
      for (let m of this.markers) {
        bounds.extend(m.position)
      }
      this.$refs.gmap.$mapObject.fitBounds(bounds)
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position
      const t = `ATC #${marker.label}<br />${marker.timeOver}<br />${
        marker.levelUnit == 'F' ? 'FL' : 'Altitude'
      } ${marker.level}`
      this.infoOptions.content = t

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    onChartReady(chart, google, isRetry = 10) {
      if (this.positions.length == 0 && isRetry > 0) {
        setTimeout(() => this.onChartReady(chart, google, isRetry - 1), 500)
        return
      }
      this.generateBounds()
      // eslint-disable-next-line no-undef
      const data = new google.visualization.DataTable()
      data.addColumn('date', 'timeOver')
      data.addColumn('number', 'FL')
      data.addColumn({ type: 'string', role: 'tooltip' })
      const fl = this.positions.map((p, i) => [
        p.timeOver,
        p.level,
        '#' + (i + 1) + ' ' + p.timeOver.toNiceUtc() + ' FL' + p.level,
      ])

      data.addRows(fl)
      // Since the :data is reactive, we just need to update it's value
      this.chartData = data
    },
  },
}
</script>

<style scoped>
.side {
  width: 100%;
  height: 600px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
}
.sides {
  border: 2px ridge silver;
}
</style>
