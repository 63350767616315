<template>
  <v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            v-model="editedUser.firstName"
            label="First Name"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="editedUser.middleName"
            label="Middle Name"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="editedUser.lastName"
            label="Last Name"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="editedUser.nickname"
            label="Nickname"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="Reset">
            Reset
          </v-btn>
        </v-col>
        <v-col>
          <v-btn @click="Save">
            Save changes
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <pre>{{ currentUser }}</pre>
  </v-container>
</template>

<script>
import { UserDTO } from '@/api/flightbooking'
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    editedUser: UserDTO
  }),
  watch: {
    currentUser: function() {
      this.Reset()
    }
  },
  mounted() {
    this.Reset()
  },
  methods: {
    Save() {
      this.$store.dispatch('users/saveUser', this.editedUser)
    },
    Reset() {
      this.editedUser = Object.assign({}, this.currentUser)
    }
  },
  computed: {
    ...mapState('users', ['currentUser'])
  }
}
</script>

<style>
</style>