<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-icon small>mdi-clock</v-icon>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Estimated</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Actual</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Difference (min)</v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Off block</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{ flight && flight.eobt != null ? flight.eobt.toNiceUtc() : "---" }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >---</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >---</v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Take Off</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && flight.estimatedTakeOffTime != null ? flight.estimatedTakeOffTime.toNiceUtc() : "---"
        }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && flight.actualTakeOffTime != null ? flight.actualTakeOffTime.toNiceUtc() : "---"
        }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && (flight.actualTakeOffTime - flight.estimatedTakeOffTime) / 60000
        }}</v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >Landing</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && flight.estimatedTimeOfArrival != null ? flight.estimatedTimeOfArrival.toNiceUtc() : "---"
        }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && flight.actualTimeOfArrival != null ? flight.actualTimeOfArrival.toNiceUtc() : "---"
        }}</v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          outlined
          tile
        >{{
          flight && (flight.actualTimeOfArrival - flight.estimatedTimeOfArrival) / 60000
        }}</v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FlightDTO } from '../../api/flightbooking'
export default {
  data: () => ({}),
  props: {
    flight: null
  }
}
</script>

<style></style>
